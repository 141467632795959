import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as ExcelJs from "exceljs";
import { saveAs } from 'file-saver-es';
import { RoutingUrlConstants } from 'src/app/core/constants/routing-url.constants';
import { ReportConstant } from 'src/app/modules/report/constants/report.constant';
import { ComponentModel } from 'src/app/shared/interface/popup.interface';
import { PopupService } from 'src/app/shared/services/popup.service';
import { RenewalsService } from '../../../shared/services/renewals.service';

@Component({
    selector: 'report-popup-data',
    templateUrl: './report-popup.component.html',
    styleUrls: ['./report-popup.component.sass'],
    encapsulation: ViewEncapsulation.None
})
export class ReportPopupComponent extends ReportConstant implements OnInit {
    data: any;

    report: boolean = false;
    download_report: boolean = false;
    maxRowsErr: boolean = false;

    reportType!: number;
    isLoading = false;
    summaryHeaderForExcel: any;
    summaryKeysToIncludeInExcel: any;
    method = false;
    confirm = false;
    outsideScheduleDate = false;
    expired = false;
    passedScheduleDate = false;
    constructor(
        translate: TranslateService,
        private router: Router,
        private renewalService: RenewalsService,
        private popupService: PopupService,
        private datePipe: DatePipe,
        public dialogRef?: MatDialogRef<any>

    ) {
        super(translate);
    }
    ngOnInit(): void {
        if (this.data == 'report') { this.report = true; }
        else if (this.data == this.summary || this.data == this.detail) {
            this.maxRowsErr = true;
            this.download_report = false;
        }
        else if (this.data == 'method') {
            this.maxRowsErr = false;
            this.download_report = false;
            this.method = true;
        }
        else if (this.data.popudata == 'confirm') {
            this.maxRowsErr = false;
            this.download_report = false;
            this.method = false;
            this.confirm = true;
        }
        else if (this.data.popudata == 'outsideScheduleDate') {
            this.maxRowsErr = false;
            this.download_report = false;
            this.method = false;
            this.outsideScheduleDate = true;
        }
        else if (this.data.popudata == 'expired') {
            this.maxRowsErr = false;
            this.download_report = false;
            this.method = false;
            this.expired = true;
        }
        else if (this.data.popudata == 'passedScheduleDate') {
            this.maxRowsErr = false;
            this.download_report = false;
            this.method = false;
            this.passedScheduleDate = true;
        }
        else {
            this.download_report = true;
            const loader = this.exportToExcel(this.data, this.isLoading);
            this.report = false; this.maxRowsErr = false;
        }
    }

    onButtonClick() {
        this.dialogRef?.close(this.data);
    }
    redirectTo(type :string) {
        type == 'pay' ? this.router.navigate([RoutingUrlConstants.ORC_HELP]) :
        this.router.navigate([RoutingUrlConstants.ORC_AVAILABLE_PAYMENT_TYPES]);
        this.dialogRef?.close('closed');
    }

    exportToExcel(payload: any, isLoading: boolean) {
        this.isLoading = true;
        let workbook = new ExcelJs.Workbook();
        let fileName: string = '';
        payload.reportType == this.summary ? fileName = this.excelFileName + '-summary' : fileName = this.excelFileName + '-detail';
        if (payload.renewalCategoryID.length == 1) {
            payload.renewalCategoryID[0] == 1 ? (this.summaryHeaderForExcel = this.pendingsummaryHeaderForExcel,
                this.summaryKeysToIncludeInExcel = this.pendingSummaryKeysToIncludeInExcel) :
                payload.renewalCategoryID[0] == 2 ? (this.summaryHeaderForExcel = this.activesummaryHeaderForExcel,
                    this.summaryKeysToIncludeInExcel = this.activeSummaryKeysToIncludeInExcel) :
                    payload.renewalCategoryID[0] == 3 ? (this.summaryHeaderForExcel = this.historySummaryHeaderForExcel,
                        this.summaryKeysToIncludeInExcel = this.historySummaryKeysToIncludeInExcel) : '';
        }
        else {
            this.summaryHeaderForExcel = this.allSummaryHeaderForExcel;
            this.summaryKeysToIncludeInExcel = this.allsSmmaryKeysToIncludeInExcel;
        }
        let worksheet = workbook.addWorksheet(fileName);
        const mergeCols: string[] = [];
        const mergeColsOfHeaderTotal: string[] = [];
        const headersofExcel: string[] = [];
        const requiredSpace: string[] = [];
        const requiredHeaders = [...this.detailHeadersRequiredForExcel, ...this.lineLevelHeader];
        const openTextImage = workbook.addImage({
            base64: this.openTextLogo,
            extension: 'png'
        });

        worksheet.addImage(openTextImage, {
            tl: { col: 0, row: 0 },
            ext: { width: 412, height: 49 }
        });
        //Merging Header level and line level headers
        let spacelength = 0;
        payload.reportType == this.summary ?
            this.summaryHeaderForExcel.forEach((e: any, i: number) => {
                headersofExcel.push(e.value);
            }) : (requiredHeaders.forEach((e: any, i: number) => {
                e.value == 'Amount' ? spacelength = i : '';
                headersofExcel.push(e.value);
            }), [...Array(spacelength - 1)].forEach((e, i) => requiredSpace.push(''))) //Placing subtotal at Amount column);


        worksheet.getRow(4).values = headersofExcel;
        const titleRow4 = worksheet.getRow(4);
        payload.reportType == this.summary ? worksheet.mergeCells(`A1:${worksheet.columns[headersofExcel.length - 1].letter}3`) : worksheet.mergeCells(`A1:${worksheet.columns[headersofExcel.length - 1].letter}3`);
        titleRow4.font = { family: 4, size: 10, bold: true };
        titleRow4.alignment = { horizontal: 'center' }
        let chk = false;
        let i = 5;
        let colMergeStartIndex = 0;
        let colMergeEndIndex = 0;
        //For Details cell's merge
        if (payload.reportType == this.detail) {
            this.detailHeadersRequiredForExcel.forEach((e: any, i: number) => {
                e.key = worksheet.columns[i].letter;
                mergeCols.push(e.key);
            })
            this.lineLevelHeader.forEach((e: any, i: number) => {
                this.detailHeadersRequiredForExcel.length + i == spacelength
                    || this.detailHeadersRequiredForExcel.length + i == spacelength - 1 || worksheet.getRow(4).getCell(this.detailHeadersRequiredForExcel.length + i + 1).value == this.currencyKey
                    ? '' : (e.key = worksheet.columns[this.detailHeadersRequiredForExcel.length + i].letter, mergeColsOfHeaderTotal.push(e.key));
            })
        }

        this.renewalService
            .getReport(payload)
            .subscribe((e: any) => {

                e.length == 0 ? (this.dialogRef?.close(), this.isLoading = false) : '';

                // Details page Excel
                if (payload.reportType == this.detail) {
                    e.result.map((row: any, index: number) => {
                        //Header Details
                        const RcDetailsWithoutLines = this.keysToIncludeInExcel.map(fieldName => (row[fieldName]));
                        let chkLineDetailsAmount: boolean = true;
                        row['chkLineDetailsAmount'] != 1 ? chkLineDetailsAmount = false : '';

                        //Line Level Data

                        if (row.lines.length == 0) {
                            worksheet.addRow(RcDetailsWithoutLines);
                            colMergeStartIndex = i;
                            colMergeEndIndex = i;
                        }
                        else {
                            row.lines && row.lines.map((line: any) => {
                                const spaces = this.keysToIncludeInExcel.map(fieldName => JSON.stringify(""));
                                if (chk) {
                                    let RcDetailsOfLines2 = chkLineDetailsAmount ?
                                        spaces.concat(this.keysToIncludeInExcelLineData.map((fieldName: any) => (line[fieldName] == null ? ('') : (line[fieldName]))))
                                        : spaces.concat(this.keysToIncludeInExcelLineData.map((fieldName: any) => (line[fieldName] == null || fieldName == 'amount' ? "" : (line[fieldName]))))

                                    worksheet.addRow(RcDetailsOfLines2);
                                    i += 1;
                                    colMergeEndIndex = i;
                                } else {
                                    chk = true;
                                    let RcDetailsOfLines2 = RcDetailsWithoutLines.concat(
                                        chkLineDetailsAmount ?
                                            (this.keysToIncludeInExcelLineData.map((fieldName: any) => (line[fieldName] == null ? ('') : (line[fieldName]))))
                                            : (this.keysToIncludeInExcelLineData.map((fieldName: any) => (line[fieldName] == null || fieldName == 'amount' ? "" : (line[fieldName]))))

                                    );
                                    worksheet.addRow(RcDetailsOfLines2);
                                    colMergeStartIndex = i;
                                    row.lines.length == 1 ? colMergeEndIndex = colMergeEndIndex + 1 : '';
                                }
                            });
                        }
                        //SubTotal, Estimated and Total
                        let subTotal, estimatedTax;
                        row['subTotal'] ? subTotal = Number(row['subTotal']) : subTotal = '';
                        row['estimatedTax'] ? estimatedTax = Number(row['estimatedTax']) : estimatedTax = 0;
                        const total: any = Number(subTotal) + Number(estimatedTax);
                        const headofTotal: any = [
                            { key: this.headerofTotal[0].value, value: subTotal, index: 1 },
                            { key: this.headerofTotal[1].value, value: estimatedTax, index: 2 },
                            { key: this.headerofTotal[2].value, value: '', index: 3 },
                            { key: this.headerofTotal[3].value + row['totalAsOfDate'], value: '', index: 4 }
                        ];
                        colMergeEndIndex += headofTotal.length;
                        let rowBoldIndex = colMergeEndIndex - 3;
                        rowBoldIndex < this.rowBolededSizeSix ? rowBoldIndex = this.rowBolededSizeSix : '';
                        headofTotal.forEach((rowTotal: any) => {
                            let RcDetailsWithoutLinesofTotal = requiredSpace.map(fieldName => { });
                            RcDetailsWithoutLinesofTotal = RcDetailsWithoutLinesofTotal.concat(rowTotal.key);
                            rowTotal.key == 'Total' || rowTotal.index == 4 ?
                                RcDetailsWithoutLinesofTotal = RcDetailsWithoutLinesofTotal.concat(total)
                                : RcDetailsWithoutLinesofTotal = RcDetailsWithoutLinesofTotal.concat(rowTotal.value);
                            RcDetailsWithoutLinesofTotal = RcDetailsWithoutLinesofTotal.concat(row['currency']);
                            worksheet.addRow(RcDetailsWithoutLinesofTotal);
                            worksheet.getRow(rowBoldIndex).font = { family: 4, size: 10, bold: true };
                            rowBoldIndex += 1;
                        });

                        //Merging cells
                        
                        chk = false;
                        mergeCols.forEach(col => {
                            colMergeEndIndex - headofTotal.length + 1 < this.minLineLevelsize ? 
                            ((colMergeEndIndex = colMergeEndIndex + headofTotal.length),worksheet.mergeCells(`${col}${colMergeStartIndex}:${col}${colMergeEndIndex}`)) :
                            worksheet.mergeCells(`${col}${colMergeStartIndex}:${col}${colMergeEndIndex}`);
                        })
                        
                        mergeColsOfHeaderTotal.forEach(col => {
                            colMergeEndIndex - headofTotal.length + 1 < this.minLineLevelsize ?
                             (worksheet.mergeCells(`${col}${colMergeStartIndex}:${col}${colMergeEndIndex + headofTotal.length}`)) :
                            worksheet.mergeCells(`${col}${colMergeEndIndex - headofTotal.length + 1}:${col}${colMergeEndIndex}`);
                        });

                        i += 5;
                    })
                }
                else if (payload.reportType == this.summary) {

                    e.result.map((row: any) => {
                        //Summary page Excel
                        const RcDetailsWithoutLines = this.summaryKeysToIncludeInExcel.map((fieldName: string) =>
                            row[fieldName]
                        );
                        worksheet.addRow(RcDetailsWithoutLines);
                    });

                };

                this.worksheetColumnStyle(worksheet, payload.reportType);

                //Generating Excel File
                (payload.reportType == this.summary && e.count <= this.maxRowsAllowedSummary
                    || payload.reportType == this.detail && e.count <= this.maxRowsAllowedDetail) ?
                    workbook.xlsx.writeBuffer().then((data) => {
                        let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                        saveAs(blob, this.getExcelFileName(fileName));
                        this.dialogRef?.close();
                    }) : (this.dialogRef?.close(), this.showInformationModel(payload.reportType));
                this.isLoading = false;
            }, error => {
                this.isLoading = false;
                this.dialogRef?.close();
            });
    }
    getExcelFileName(fileName: any) {
        return fileName + "_" + this.datePipe.transform(new Date, this.datepipeFormat) + this.excelExtns;
    }
    worksheetColumnStyle(worksheet: any, reportType: string) {
        worksheet.columns.forEach(function (column: any) {
            column.width = 15;
            column.alignment = { vertical: 'top', wrapText: true }
        });
        let borderStyles = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" }
        };

        worksheet.eachRow({ includeEmpty: true }, (row: any) => {
            row.eachCell({ includeEmpty: true }, (cell: any) => {
                cell.border = borderStyles;
            })
        });
        // filter code will not work for details report as we are merging the columns in the excel. (MS excel doesn't supports)
        reportType == this.summary ? worksheet.autoFilter = `A4:${worksheet.columns[this.summaryHeaderForExcel.length - 1].letter}4` : '';
    }
    showInformationModel(popupData: any, checkedvalue?: any) {
        const dailogData: ComponentModel = {
            componentName: ReportPopupComponent,
            data: popupData
        };
        this.popupService.dialogWidth = 'medium';
        this.popupService.openPopup(dailogData);
        this.popupService.dialogRef.afterClosed().subscribe((response: any) => {
        });
    }
}
